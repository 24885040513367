<template>
  <div id="preview">
    <v-toolbar color="#8950FC">
      <v-toolbar-title>
        <h1 class="text-white">{{ pageDescription }}</h1>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container>
            <v-row wrap>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h6><span class="text-danger">*</span> First Name</h6>
                </label>
                <v-text-field
                  v-model="member.FirstName"
                  :rules="FirstNameRules"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h6>Middle Name</h6>
                </label>
                <v-text-field
                  v-model="member.MiddleName"
                  :rules="MiddleNameRules"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h6>Last Name</h6>
                </label>
                <v-text-field
                  v-model="member.LastName"
                  :rules="LastNameRules"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h6><span class="text-danger">*</span> Gender</h6>
                </label>
                <v-select
                  :reduce="(option) => option.value"
                  :items="GenderOptions"
                  :rules="GenderRules"
                  v-model="member.Gender"
                  required
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h6><span class="text-danger">*</span> Street 1</h6>
                </label>
                <v-text-field
                  v-model="member.Street1"
                  :rules="Street1Rules"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h6><span class="text-danger">*</span>Date Fo Birth</h6>
                </label>
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      required
                      v-model="member.DOB"
                      label="Date of birth"
                      readonly
                      v-on="on"
                      background-color="#F3F6F9"
                      class="form-control form-control-lg form-control-solid"
                      validate-on-blur
                      solo
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="member.DOB"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col cols="12" sm="6" lg="8" md="8">
                <label>
                  <h6>Street 2</h6>
                </label>
                <v-text-field
                  v-model="member.Street2"
                  :rules="Street2Rules"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h6>Area</h6>
                </label>
                <v-text-field
                  v-model="member.Area"
                  :rules="AreaRules"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h6><span class="text-danger">*</span> State</h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="StateCodeOptionsLoading"
                  :items="StateCodeOptions"
                  :rules="StateCodeRules"
                  v-model="StateCode"
                  required
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h6><span class="text-danger">*</span> District</h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="DistrictCodeOptionsLoading"
                  :items="DistrictCodeOptions"
                  :rules="DistrictCodeRules"
                  v-model="DistrictCode"
                  required
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h6><span class="text-danger">*</span> City</h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="CityCodeOptionsLoading"
                  :items="CityCodeOptions"
                  :rules="CityCodeRules"
                  v-model="CityCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h6><span class="text-danger">*</span> Pincode</h6>
                </label>
                <v-text-field
                  v-model="member.Pincode"
                  :rules="PincodeRules"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h6>Mobile No</h6>
                </label>
                <v-text-field
                  v-model="member.MobileNo"
                  :rules="MobileNoRules"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h6>Email Id</h6>
                </label>
                <v-text-field
                  v-model="member.EmailId"
                  :rules="EmailIdRules"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4" v-if="RoleId == 2">
                <v-select
                  :reduce="(option) => option.value"
                  :items="ActiveStatusOptions"
                  :rules="ActiveStatusRules"
                  v-model="member.UserActiveStatus"
                  label="Active Status"
                  required
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                lg="4"
                md="4"
                v-if="member.RoleOptionsFlag"
              >
                <label>
                  <h6>Role</h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="RoleCodeOptionsLoading"
                  :items="RoleCodeOptions"
                  :rules="RoleCodeRules"
                  v-model="RoleCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                lg="4"
                md="4"
                v-if="member.RoleOptionsFlag"
              >
                <label>
                  <h6>Zone</h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="ZoneCodeOptionsLoading"
                  :items="ZoneCodeOptions"
                  :rules="ZoneCodeRules"
                  v-model="ZoneCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col cols="12" sm="6" lg="4" md="4">
                <h6>Existing Member Image<small> (optional)</small></h6>
                <v-img
                  :src="member.MemberImagePath"
                  height="100"
                  contain
                  v-if="member.MemberImagePath != ''"
                />
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <h6>Upload new member image</h6>
                <v-file-input
                  :clearable="false"
                  truncate-length="25"
                  accept="image/png, image/jpeg, image/bmp"
                  placeholder="Upload member image"
                  prepend-icon="mdi-card-bulleted-settings"
                  append-outer-icon="mdi-delete"
                  v-model="UploadedImage"
                  @change="addImage(1)"
                  @click:append-outer="deleteImage(1)"
                ></v-file-input>
                <br />
                <div
                  class="actions text-center"
                  v-if="SelectedImage.ImageUrl != null"
                >
                  <b-button-group vertical class="mr-3 mb-3">
                    <v-btn
                      fab
                      dark
                      small
                      color="indigo"
                      @click.prevent="flip(true, false)"
                    >
                      <v-icon>fa fa-caret-right</v-icon>
                    </v-btn>
                  </b-button-group>

                  <b-button-group vertical class="mr-3 mb-3">
                    <v-btn
                      fab
                      dark
                      small
                      color="indigo"
                      @click.prevent="flip(false, true)"
                    >
                      <v-icon>fa fa-caret-up</v-icon>
                    </v-btn>
                  </b-button-group>

                  <b-button-group vertical class="mr-3 mb-3">
                    <v-btn
                      fab
                      dark
                      small
                      color="indigo"
                      @click.prevent="rotate(90)"
                    >
                      <v-icon>fa fa-redo</v-icon>
                    </v-btn>
                  </b-button-group>

                  <b-button-group vertical class="mr-3 mb-3">
                    <v-btn
                      fab
                      dark
                      small
                      color="indigo"
                      @click.prevent="rotate(-90)"
                    >
                      <v-icon>fa fa-undo</v-icon>
                    </v-btn>
                  </b-button-group>
                </div>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <h4>Uploaded image will appear here</h4>
                <cropper
                  ref="cropper"
                  :src="SelectedImage.ImageUrl"
                  :stencil-props="{
                    handlers: {},
                    movable: false,
                    scalable: false,
                    aspectRatio: 1,
                    previewClass: 'preview',
                  }"
                  :stencil-size="{
                    width: 300,
                    height: 300,
                  }"
                  image-restriction="stencil"
                />
              </v-col>
            </v-row>
          </v-container>
          <v-container>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid"
                  type="submit"
                  color="success"
                  class="mr-4"
                  @click.prevent="getCroppedImage"
                >
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
          <v-container class="py-0">
            <v-row wrap v-if="ProgessStart == 1">
              <v-col align="center" cols="12" md="12">
                <v-progress-circular
                  :rotate="180"
                  :size="100"
                  :width="15"
                  :value="uploadPercentage"
                  color="#00a4ef"
                >
                  {{ uploadPercentage }}
                </v-progress-circular>
                <h3 class="red--text">
                  Please wait, we are uploading your details. Time may vary
                  based on your internet connection.
                </h3>
              </v-col>
            </v-row>
          </v-container>
          <v-container class="py-0">
            <v-row wrap v-if="ErrorMessageTxtFlag == 1">
              <v-col align="center" cols="12" md="12">
                <h3 class="red--text">{{ ErrorMessageTxt }}</h3>
              </v-col>
            </v-row>
          </v-container>
          <v-container class="py-0">
            <v-row wrap v-if="SuccessMessageTxtFlag == 1">
              <v-col align="center" cols="12" md="12">
                <h3 class="green--text">{{ SuccessMessageTxt }}</h3>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closePrompt" color="#a52a2a" class="white--text">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";

export default {
  mixins: [common],
  components: {},
  props: {
    editRecordPrompt: {
      type: Boolean,
      required: true,
    },
    recordIndex: {
      type: Number,
      required: true,
    },
    pageDescription: {
      type: String,
    },
  },
  data() {
    return {
      valid: true,

      rows: [],
      records: [],
      member: {},

      alert: {},

      RoleId: "",
      ResultFlag: false,

      ProgessStart: 0,
      uploadPercentage: 0,

      SuccessMessageTxtFlag: 0,
      SuccessMessageTxt: "",

      ErrorMessageTxtFlag: 0,
      ErrorMessageTxt: "",

      FirstName: "",
      FirstNameRules: [(v) => !!v || "First Name is required"],
      MiddleNameRules: [],
      LastNameRules: [],
      Street1Rules: [(v) => !!v || "Street 1 is required"],
      Street2Rules: [],
      PincodeRules: [(v) => !!v || "Pincode is required"],
      AreaRules: [],
      MobileNoRules: [],
      EmailIdRules: [],

      FromDate: "",
      FromDateRules: [(v) => !!v || "From date is required"],
      menu: false,

      StateCodeRules: [(v) => !!v || "State is required"],
      StateCode: "",
      StateCodeOptions: [],
      StateCodeOptionsLoading: false,

      DistrictCodeRules: [(v) => !!v || "District is required"],
      DistrictCode: "",
      DistrictCodeOptions: [],
      DistrictCodeOptionsLoading: false,

      CityCodeRules: [(v) => !!v || "City is required"],
      CityCode: "",
      CityCodeOptions: [],
      CityCodeOptionsLoading: false,

      GenderRules: [(v) => !!v || "Gender is required"],
      Gender: "",
      GenderOptions: [
        { value: "", text: "--Select--" },
        { value: 1, text: "Male" },
        { value: 2, text: "Female" },
        { value: 3, text: "Others" },
      ],

      RoleCodeRules: [],
      RoleCode: "",
      RoleCodeOptions: [],
      RoleCodeOptionsLoading: false,

      ZoneCodeRules: [],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      ActiveStatus: "",
      ActiveStatusRules: [],
      ActiveStatusOptions: [
        { value: 0, text: "Inactive" },
        { value: 1, text: "Active" },
        { value: 2, text: "Suspended" },
      ],

      UploadedImage: {},

      SelectedImage: {
        ImageType: "",
        ImageUrl: null,
      },
    };
  },
  computed: {},
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    member: function () {
      console.log("watch member");
      var rows = this.member;
      var n1 = Object.keys(rows).length;
      // var n1 = this.rows.length;
      console.log("n1=" + n1 + ", rows=" + JSON.stringify(rows));
      if (n1 > 0) {
        var StateCode = rows.StateId;
        console.log({ StateCode });
        this.StateCode = StateCode;
        this.getDistrictCodeOptions();
        var DistrictCode = rows.DistrictId;
        console.log({ DistrictCode });
        this.DistrictCode = DistrictCode;
        this.getCityCodeOptions();
        var CityCode = rows.CityId;
        console.log({ CityCode });
        this.CityCode = CityCode;
      }
    },
    StateCodeOptions: function () {
      console.log("watch StateCodeOptions");
      this.LoadingFlag = false;
      this.StateCodeOptionsLoading = false;
    },
    StateCode: function () {
      console.log("watch StateCode");
      this.LoadingFlag = true;
      this.getDistrictCodeOptions();
    },
    DistrictCodeOptions: function () {
      console.log("watch DistrictCodeOptions");
      this.LoadingFlag = false;
      this.DistrictCodeOptionsLoading = false;
    },
    DistrictCode: function () {
      console.log("watch DistrictCode");
      this.LoadingFlag = true;
      this.getCityCodeOptions();
    },
    CityCodeOptions: function () {
      console.log("watch CityCodeOptions");
      this.LoadingFlag = false;
      this.CityCodeOptionsLoading = false;
    },
    RoleCodeOptions: function () {
      console.log("watch RoleCodeOptions");
      this.LoadingFlag = false;
      this.RoleCodeOptionsLoading = false;
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    reset() {
      console.log("reset called");
      this.$refs.form.reset();
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideEditRecordPrompt", this.ResultFlag);
    },
    resetMessageTxt() {
      console.log("resetMessageTxt called");
      this.ErrorMessageTxt = 0;
      this.ErrorMessageTxtFlag = "";
      this.SuccessMessageTxtFlag = 0;
      this.SuccessMessageTxt = "";
    },
    refreshPageData() {
      console.log("refreshPageData called");
      var recordIndex = this.recordIndex;
      var member = this.member;
      console.log(
        "recordIndex=" + recordIndex + ", member=" + JSON.stringify(member)
      );
      this.getRecordDetails();
      this.getStateCodeOptions();
    },
    getStateCodeOptions() {
      console.log("getStateCodeOptions called");
      this.LoadingFlag = true;
      this.StateCodeOptionsLoading = true;
      var selectbox1_source = "StateCode";
      var selectbox1_destination = "StateCodeOptions";
      var selectbox1_url = "api/states/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getDistrictCodeOptions() {
      console.log("getDistrictCodeOptions called");
      var StateCode = this.StateCode;
      console.log({ StateCode });
      if (StateCode != "") {
        this.LoadingFlag = true;
        this.DistrictCodeOptionsLoading = true;
        var selectbox1_source = "DistrictCode";
        var selectbox1_destination = "DistrictCodeOptions";
        var selectbox1_url = "api/district/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          StatesCode: StateCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getCityCodeOptions() {
      console.log("getCityCodeOptions called");
      var DistrictCode = this.DistrictCode;
      console.log({ DistrictCode });
      if (DistrictCode != "") {
        this.CityCodeOptionsLoading = true;
        var selectbox1_source = "CityCode";
        var selectbox1_destination = "CityCodeOptions";
        var selectbox1_url = "api/city/options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          DistrictCode: DistrictCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getRoleCodeOptions() {
      console.log("getRoleCodeOptions called");
      this.RoleCodeOptionsLoading = true;
      var selectbox1_source = "RoleCode";
      var selectbox1_destination = "RoleCodeOptions";
      var selectbox1_url = "api/roles/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var selectbox1_source = "ZoneCode";
      var selectbox1_destination = "ZoneCodeOptions";
      var selectbox1_url = "api/year-wise-lom/zone-options";
      var selectbox1_conditions_array = {
        UserInterface: 4,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getRecordDetails() {
      // add todo in store and clear all fields in dialog
      console.log("getRecordDetails called");

      this.resetMessageTxt();

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;

      console.log("server_url=" + server_url + ", token=" + token);

      var MemberId = this.recordIndex;

      console.log("MemberId=" + MemberId);

      if (MemberId != "") {
        var upload = {
          UserInterface: 1,
          MemberId: MemberId,
        };
        console.log("upload=" + JSON.stringify(upload));

        const thisIns = this;
        var output = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;
        var records = [];

        // thisIns.pageLoadingOn()
        thisIns.ProgessStart = 1;
        thisIns.valid = false;

        var CancelToken = this.CancelToken;
        var cancel;

        var add_url = server_url + "api/members/show";

        console.log("add_url=" + add_url);

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
          cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            cancel = c;
          }),
        })
          .then(function (response) {
            // thisIns.pageLoadingOff()
            thisIns.ProgessStart = 0;
            thisIns.valid = true;

            console.log("response=" + JSON.stringify(response));
            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log(
              "output=" +
                output +
                ", flag=" +
                flag +
                ", records=" +
                JSON.stringify(records)
            );

            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              // thisIns.SuccessMessageTxtFlag = 1;
              // thisIns.SuccessMessageTxt = output;

              thisIns.member = records[0];
              var member = records[0];
              console.log({ member });
              if (records[0].RoleOptionsFlag) {
                thisIns.RoleCode = records[0].UserRoleId;
                thisIns.getRoleCodeOptions();
                thisIns.getZoneCodeOptions();
                thisIns.ZoneCode = records[0].SmaZoneId;
                var ZoneCode = records[0].SmaZoneId;
                console.log({ ZoneCode });
              }
            } else {
              thisIns.ErrorMessageTxtFlag = 1;
              thisIns.ErrorMessageTxt = output;
              /*
              thisIns.alert = {
                flag: true,
                color: "error",
                message: errorTxt,
              };
              */
            }
          })
          .catch(function (error) {
            thisIns.ProgessStart = 0;
            thisIns.valid = true;

            console.log("error=" + error);
            var errCode = error.response.status;
            thisIns.ErrorMessageTxtFlag = 1;
            errorTxt = "";
            if (thisIns.$http.isCancel(error)) {
              // console.log('im canceled')
              // errorTxt += "Network error. Kindly try again. "
              errorTxt +=
                "This is taking longer than usual. Kindly contact JCI India NHQ. ";
            } else {
              errorTxt +=
                "This is taking longer than usual. Kindly contact JCI India NHQ.. ";
              if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.headers);
                errorTxt += " .";
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
                errorTxt += " ..";
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error);
                errorTxt += " ...";
              }
            }
            thisIns.ErrorMessageTxt = errorTxt;
          });
      } else {
        console.log("some errors occured");
        var message = "City Id should not be empty";
        this.alert = {
          flag: true,
          color: "error",
          message: message,
        };
      }
    },
    addImage(id) {
      console.log("addImage called");
      console.log({ id });
      switch (id) {
        case 1:
          var file = this.UploadedImage;
          console.log({ file });
          var src = URL.createObjectURL(file);
          console.log({ src });
          if (this.SelectedImage.src) {
            URL.revokeObjectURL(this.SelectedImage.src);
          }
          var FileType = file.type;
          console.log({ FileType });
          this.SelectedImage = {
            ImageType: FileType,
            ImageUrl: src,
            UploadedImage: file,
          };
          break;

        default:
          break;
      }
    },
    deleteImage(id) {
      console.log("deleteImage called");
      console.log({ id });
      switch (id) {
        case 1:
          this.SelectedImage = {
            ImageType: null,
            ImageUrl: null,
          };
          this.UploadedImage = {};
          break;

        default:
          break;
      }
    },
    getCroppedImage() {
      console.log("getCroppedImage called");
      var validate1 = this.$refs.form.validate();
      console.log("validate1=" + validate1);

      // validate1 = false;

      if (validate1) {
        var member = this.member;

        let upload = new FormData();
        upload.append("UserInterface", 2);
        upload.append("MemberId", member.MemberId);
        upload.append("FirstName", member.FirstName);
        upload.append("MiddleName", member.MiddleName);
        upload.append("LastName", member.LastName);
        upload.append("MobileNo", member.MobileNo);
        upload.append("EmailId", member.EmailId);
        upload.append("Street1", member.Street1);
        upload.append("DOB", member.DOB);
        upload.append("Street2", member.Street2);
        upload.append("Area", member.Area);
        upload.append("CityId", this.CityCode);
        upload.append("Pincode", member.Pincode);
        upload.append("UserActiveStatus", member.UserActiveStatus);
        upload.append("RoleCode", this.RoleCode);
        upload.append("Gender", member.Gender);
        upload.append("Zone", this.ZoneCode);

        var file = this.UploadedImage;
        var FileName = file.name;
        console.log({ FileName });
        var FileType = file.type;
        console.log({ FileType });

        const { canvas } = this.$refs.cropper.getResult();
        if (canvas) {
          canvas.toBlob((blob) => {
            upload.append("ProfilePicture", blob, FileName);
            this.submitForm(upload);
          }, FileType);
        } else {
          upload.append("ProfilePicture", null);
          this.submitForm(upload);
        }
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        this.sweetAlert("error", output, false);
      }
    },
    submitForm(upload) {
      // add todo in store and clear all fields in dialog
      console.log("submitForm called");
      console.log({ upload });

      this.resetMessageTxt();

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;

      console.log("server_url=" + server_url + ", token=" + token);

      var MemberId = this.recordIndex;
      var member = this.member;
      member.StateId = this.StateCode;
      member.DistrictId = this.DistrictCode;
      member.CityId = this.CityCode;
      member.DOB = this.DOB;

      console.log({ MemberId });
      console.log({ member });

      var result = this.$refs.form.validate();
      console.log("result=" + result);

      // result = false;

      if (result) {
        const thisIns = this;
        var output = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;
        var records = [];

        // thisIns.pageLoadingOn()
        thisIns.ProgessStart = 1;
        thisIns.valid = false;

        var CancelToken = this.CancelToken;
        var cancel;

        var add_url = server_url + "api/members/name-address-update";

        console.log("add_url=" + add_url);

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
          cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            cancel = c;
          }),
        })
          .then(function (response) {
            // thisIns.pageLoadingOff()
            thisIns.ProgessStart = 0;
            thisIns.valid = true;
            thisIns.ResultFlag = true;

            console.log("response=" + JSON.stringify(response));
            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log(
              "output=" +
                output +
                ", flag=" +
                flag +
                ", records=" +
                JSON.stringify(records)
            );

            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              // thisIns.reset();

              thisIns.SuccessMessageTxtFlag = 1;
              thisIns.SuccessMessageTxt = output;

              thisIns.alert = {
                flag: true,
                color: "info",
                message: successTxt,
              };

              thisIns.closePrompt();

              // thisIns.$router.push("/lom-list");
            } else {
              thisIns.ErrorMessageTxtFlag = 1;
              thisIns.ErrorMessageTxt = errorTxt;

              thisIns.alert = {
                flag: true,
                color: "error",
                message: errorTxt,
              };
            }
          })
          .catch(function (error) {
            thisIns.ProgessStart = 0;
            thisIns.valid = true;

            console.log("error=" + error);
            var errCode = error.response.status;
            thisIns.ErrorMessageTxtFlag = 1;
            errorTxt = "";
            if (thisIns.$http.isCancel(error)) {
              // console.log('im canceled')
              // errorTxt += "Network error. Kindly try again. "
              errorTxt +=
                "This is taking longer than usual. Kindly login with your President's username and password for acknowledgement. ";
            } else {
              errorTxt +=
                "This is taking longer than usual. Kindly login with your President's username and password for acknowledgement.. ";
              if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.headers);
                errorTxt += " .";
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
                errorTxt += " ..";
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error);
                errorTxt += " ...";
              }
            }
            thisIns.ErrorMessageTxt = errorTxt;
          });
      } else {
        console.log("some errors occured");
        var message = "Kindy fill required fields and then submit";
        this.alert = {
          flag: true,
          color: "error",
          message: message,
        };
      }
    },
  },
  beforeMount() {
    var RoleId = this.$session.get("RoleId");
    RoleId = RoleId == (null || undefined) ? "" : RoleId;
    RoleId = 1;
    this.RoleId = RoleId;
    this.refreshPageData();
  },
};
</script>
